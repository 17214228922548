export const WEBSITE: any = {
  safetyculture: {
    speedInsightsEndpoint: 'marketing.scvercel.dev/_vercel/speed-insights',
    banners: {
      cookie: {
        critical: 'safetyculture_marketing_critical_banner',
        noneCritical: 'safetyculture_marketing_non_critical_banner'
      }
    },
    productFeature: 'marketing',
    hubspot: {
      portalId: '45078707',
    },
    maxPagedPaths: 2,
    copyright: {
      de: false,
      en: false,
      es: false,
      fr: false,
      nl: false,
      'pt-br': false,
    },
    cta: {
      login: {
        external: {
          de: false,
          en: false,
          es: false,
          fr: false,
          nl: false,
          'pt-br': false,
        },
        label: {
          de: 'Anmelden',
          en: 'Log in',
          es: 'Iniciar sesión',
          fr: 'Connexion',
          nl: 'Inloggen',
          'pt-br': 'Fazer login',
        },
        link: {
          de: 'https://app.safetyculture.com/login?lang=de',
          en: 'https://app.safetyculture.com/login',
          es: 'https://app.safetyculture.com/login?lang=es',
          fr: 'https://app.safetyculture.com/login?lang=fr',
          nl: 'https://app.safetyculture.com/loginlang=nl',
          'pt-br': 'https://app.safetyculture.com/login?lang=pt-br',
        },
      },
      signup: {
        external: {
          de: false,
          en: false,
          es: false,
          fr: false,
          nl: false,
          'pt-br': false,
        },
        label: {
          de: 'Kostenlos registrieren',
          en: 'Sign up for free',
          es: 'Regístrese de forma gratuita',
          fr: "S'inscrire gratuitement",
          nl: 'Meld u gratis aan',
          'pt-br': 'Cadastre-se gratuitamente',
        },
        link: {
          de: 'https://app.safetyculture.com/sign-up?lang=de',
          en: 'https://app.safetyculture.com/sign-up',
          es: 'https://app.safetyculture.com/sign-up?lang=es',
          fr: 'https://app.safetyculture.com/sign-up?lang=fr',
          nl: 'https://app.safetyculture.com/sign-up?lang=nl',
          'pt-br': 'https://app.safetyculture.com/sign-up?lang=pt-br',
        },
      },
    },
    cookiebot: 'a200e705-4360-46f7-b1dd-6b74d2fe303e',
    gtm: {
      production: 'GTM-5TNTR5X',
      testing: 'GTM-M92BT67C',
    },
    locales: {
      default: 'en',
      hreflang: {
        de: 'de-de',
        en: 'en-us',
        es: 'es-es',
        fr: 'fr-fr',
        nl: 'nl-nl',
        'pt-br': 'pt-br',
      },
      label: {
        de: 'Deutsch',
        en: 'English',
        es: 'Español',
        fr: 'Français',
        nl: 'Nederlands',
        'pt-br': 'Português',
      },
      languages: ['de', 'en', 'es', 'fr', 'nl', 'pt-br'],
      openGraph: {
        de: 'de_DE',
        en: 'en_US',
        es: 'es_ES',
        fr: 'fr_FR',
        nl: 'nl_NL',
        'pt-br': 'pt_BR',
      },
      toggle: true,
      regions: {
        apac: ['AU'],
        us: ['US', 'CA'],
        emea: ['GB', 'IE'],
      },
      pageLimit: {
        de: 12,
        en: 48,
        es: 12,
        fr: 12,
        nl: 12,
        'pt-br': 12,
      },
    },
    logo: {
      alt: 'SafetyCulture',
      height: 30,
      image: 'safetyculture.svg',
      width: 202,
    },
    products: true,
    root: 'front-page',
    store: true,
    title: 'SafetyCulture',
    types: [
      'ebook',
      'ebooks',
      'event',
      'events',
      'legal',
      'news',
      'page',
      'partnerships',
      'customer',
      'customers',
      'checklist',
      'checklists',
      'app',
      'apps',
      'topic',
      'topics',
      'author',
      'authors',
    ],
  },
  'safetyculture-care': {
    speedInsightsEndpoint: 'care.scvercel.dev/_vercel/speed-insights',
    banners: {
      cookie: {
        critical: 'safetyculture_care_critical_banner',
        noneCritical: 'safetyculture_care_non_critical_banner'
      }
    },
    productFeature: 'care',
    hubspot: {
      portalId: '46933274',
    },
    copyright: {
      au: false,
      us: '<a href="/us/licensed/"><u>Licensed in</u></a> and D.C. NPN #20605820',
    },
    cta: {
      login: {
        external: {
          au: true,
          us: false,
        },
        label: {
          au: 'Login',
          us: false,
        },
        link: {
          au: 'https://app.care.safetyculture.com/',
          us: false,
        },
      },
      signup: {
        external: {
          au: true,
          us: false,
        },
        label: {
          au: 'Get started',
          us: 'Get appointed',
        },
        link: {
          au: 'https://app.care.safetyculture.com/sign-up/',
          us: '/get-appointed/',
        },
      },
    },
    gtm: {
      production: 'GTM-PS2H8RH',
    },
    locales: {
      default: 'au',
      hreflang: {
        au: 'en-au',
        us: 'en-us',
      },
      label: {
        au: 'English (AU)',
        us: 'English (US)',
      },
      languages: ['au', 'us'],
      openGraph: {
        au: 'en_AU',
        us: 'en_US',
      },
      toggle: false,
    },
    logo: {
      alt: 'SafetyCulture Care',
      height: 48,
      image: 'safetyculture-care.svg',
      width: 92,
    },
    products: false,
    root: 'front-page',
    store: false,
    title: 'SafetyCulture Care',
    types: ['page'],
  },
  dateLocales: {
    en: 'en-AU',
    au: 'en-AU',
    us: 'en-US',
    fr: 'fr-FR',
    'pt-br': 'pt-BR',
    de: 'de-DE',
    nl: 'nl-NL',
    es: 'es-ES',
  },
};

export default WEBSITE;
