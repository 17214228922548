import { createContext, useContext, useEffect, useState } from 'react';

const NonceContext = createContext<string>('');

interface NonceProps {
  children: React.ReactNode;
}

export const Nonce = ({ children }: NonceProps) => {
  const [nonce, setNonce] = useState<string>('');

  useEffect(() => {
    const nonceValue = document.body.getAttribute('data-nonce') || '';
    setNonce(nonceValue);
  }, []);

  return (
    <NonceContext.Provider value={nonce}>{children}</NonceContext.Provider>
  );
};

export const useNonce = () => useContext(NonceContext);

export default Nonce;
